import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import { saveAs } from "file-saver";
import { msalConfig } from '../auth/authConfig';
import { errorHandler } from '../utils/helpers';

interface FetchReportParams {
  token: string | null;
  setError: (data: any) => void;
  setLoading: (data: boolean) => void;
}

const fetchData = async ({
  token,
  setError,
  setLoading,
}: FetchReportParams) => {
  try {
    const response = await axios.get(
      `${window.__env.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/Report/SensorsInventory`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json, text/plain, */*',
        },
        responseType: "blob",
      }
    );

    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'installation_report';

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
      if (fileNameMatch && fileNameMatch.length > 1) {
        fileName = fileNameMatch[1];
      }
    }

    fileName = fileName.replace(/[/\\?%*:|"<>]/g, '-');

    saveAs(response.data, fileName);

  } catch (error) {
    errorHandler(error, setError);
  } finally {
    setLoading(false);
  }
};

interface FetchSensorsInventoryReport {
  fetchSensorsInventoryReport: () => void;
  loading: boolean;
  error: Error | null;
}

const useFetchSensorsInventoryReport = (): FetchSensorsInventoryReport => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchSensorsInventoryReport = () => {
    if (accounts.length > 0) {
      const accessTokenRequest = {
        scopes: [`${msalConfig.auth.clientId}/.default`],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((response) =>
          fetchData({
            token: response.accessToken,
            setError,
            setLoading,
          })
        )
        .catch((error) => {
          errorHandler(error, setError);
          setLoading(false);
        });
    } else {
      navigate('/sign-in');
    }
  };

  return { fetchSensorsInventoryReport, loading, error };
};

export default useFetchSensorsInventoryReport;
