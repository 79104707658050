import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import DatePicker from '../components/DatePicker';
import { FormBlockTitle } from './formCommon';
import Select from '../components/Select';
import useMediaQuery from '@mui/material/useMediaQuery';
import useFetchSensorsInventoryReport from '../hooks/useFetchSensorsInventoryReport';
import { IPAD_MEDIA_QUERY_WIDTH, MOBILE_MEDIA_QUERY_WIDTH } from '../utils/constants';

const REPORT_TYPES = [{
  id: 'Inventory',
  description: 'Inventory',
}];

const ContentWrap = styled('div')(() => ({
  padding: 10,
}));

const InputWrapper = styled('div')(() => ({
  marginBottom: 10,
}))

const GenerateReportModal = ({ isOpen, onClose }) => {
  const [showDateForm, setShowDateForm] = useState<boolean>(false);
  const isIpad = useMediaQuery(IPAD_MEDIA_QUERY_WIDTH);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY_WIDTH);
  const maxWidth = isMobile ? 350 : isIpad ? 500 : 600;

  const { fetchSensorsInventoryReport } = useFetchSensorsInventoryReport();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      fromDate: null,
      toDate: null,
      reportType: '',
    },
  });

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [reportType, setReportType] = useState('');

  const onSubmit = () => {
    if (reportType === 'Inventory') {
      return fetchSensorsInventoryReport();
    };
  };

  const handleDateChange = (id, date) => {
    setValue(id, date, { shouldValidate: true });
    if (id === 'fromDate') {
      setFromDate(date);
    } else if (id === 'toDate') {
      setToDate(date);
    }
  };

  const handleReportTypeChange = (e) => {
    const value = e.target.value;
    setValue('reportType', value, { shouldValidate: true });
    setReportType(value);
    if (value === 'Inventory') setShowDateForm(false);
  };

  return (
    <Dialog maxWidth={maxWidth} open={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentWrap style={{ maxWidth: maxWidth }}>
          <FormBlockTitle>
            Report
          </FormBlockTitle>
          <InputWrapper>
            <Select
              labelId="report-type-label"
              id="reportType"
              label="Report Type"
              options={REPORT_TYPES}
              onChange={handleReportTypeChange}
              value={reportType}
              register={register}
              error={!!errors.reportType}
              required
            />
          </InputWrapper>
          {showDateForm ? (
            <>
              <InputWrapper>
                <DatePicker
                  setValue={handleDateChange}
                  id="fromDate"
                  required
                  label="From"
                />
              </InputWrapper>
              <InputWrapper>
                <DatePicker
                  setValue={handleDateChange}
                  id="toDate"
                  required
                  label="To"
                />
              </InputWrapper>
            </>
          ) : null}
        </ContentWrap>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button type="submit">Generate</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default GenerateReportModal;
